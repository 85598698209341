import clsx from "clsx";
import type { ReactNode } from "react";
type CardProps = {
  readonly children: ReactNode;
  readonly className?: string;
  readonly isInverted?: boolean;
};
export function Card({
  children,
  className,
  isInverted = false
}: CardProps) {
  return <article className={clsx("rounded-sm border border-nobel p-3", isInverted ? "border-muted bg-muted text-white" : "bg-white", className)} data-sentry-component="Card" data-sentry-source-file="Card.component.tsx">
      {children}
    </article>;
}
import clsx from "clsx";
import { inlineJsx } from "@/ts/locale/inline-jsx";
import { RegularLinkButton } from "../Button/RegularLinkButton/RegularLinkButton.component";
import { Card } from "../Card/Card.component";
import { Divider } from "../Divider/Divider.component";
import { List } from "../List/List.component";
import { ListItem } from "../List/ListItem.component";
import type { PaywallModel } from "../Paywall/models/paywall.model";
import { Translation } from "../Translation/Translation.component";
type ConsumerProductMatrixCardProps = {
  readonly priceWithCurrency: PaywallModel.Product["pricePerQuarter"];
  readonly billingPeriod: PaywallModel.Product["billingPeriod"];
  readonly siteName: string;
  readonly isInverted?: boolean;
  readonly apps: PaywallModel.Apps | null;
  readonly url: string;
  readonly isDisabled: boolean;
};
export function ConsumerProductMatrixCard({
  priceWithCurrency,
  billingPeriod,
  isInverted = false,
  url,
  apps,
  isDisabled,
  siteName
}: ConsumerProductMatrixCardProps) {
  return <Card className="flex h-full flex-col justify-between gap-y-2 rounded border-silver text-center lg:px-4" isInverted={isInverted} data-sentry-element="Card" data-sentry-component="ConsumerProductMatrixCard" data-sentry-source-file="ConsumerProductMatrixCard.components.tsx">
      <h1 className={clsx("hyphens-manual break-words text-xl font-bold leading-tight", isInverted ? "text-white" : "text-primary")}>
        <Translation da="Personligt abonnement" de="Persönliches Abonnement" en="Personal subscription" no="Personlig abonnement" sv="Personlig prenumeration" data-sentry-element="Translation" data-sentry-source-file="ConsumerProductMatrixCard.components.tsx" />
      </h1>
      <Divider className="relative" isLight={isInverted} orientation="horizontal" data-sentry-element="Divider" data-sentry-source-file="ConsumerProductMatrixCard.components.tsx" />
      <p className="text-left text-sm md:text-base">
        <Translation da="Med et personligt abonnement får du:" de="Mit einem persönlichen Abonnement erhalten Sie:" en="With a personal subscription you get:" no="Med et personlig abonnement får du:" sv="Med en personlig prenumeration får du:" data-sentry-element="Translation" data-sentry-source-file="ConsumerProductMatrixCard.components.tsx" />
      </p>
      <List className="gap-1 text-left text-sm md:text-base" type="checkmark" data-sentry-element="List" data-sentry-source-file="ConsumerProductMatrixCard.components.tsx">
        <ListItem data-sentry-element="ListItem" data-sentry-source-file="ConsumerProductMatrixCard.components.tsx">
          <Translation da={inlineJsx`Premium-adgang til alt indhold på ${siteName}`} de={inlineJsx`Premium-Zugang zu allen Inhalten auf ${siteName}`} en={inlineJsx`Premium access to all content on ${siteName}`} no={inlineJsx`Premium-tilgang til alt innhold på ${siteName}`} sv={inlineJsx`Premium-åtkomst till allt innehåll på ${siteName}`} data-sentry-element="Translation" data-sentry-source-file="ConsumerProductMatrixCard.components.tsx" />
        </ListItem>
        <ListItem data-sentry-element="ListItem" data-sentry-source-file="ConsumerProductMatrixCard.components.tsx">
          <Translation da="Ubegrænset adgang til vores arkiv" de="Unbegrenzten Zugriff auf unser Archiv" en="Unlimited access to our archive" no="Ubegrenset tilgang til vårt arkiv" sv="Obegränsad tillgång till vårt arkiv" data-sentry-element="Translation" data-sentry-source-file="ConsumerProductMatrixCard.components.tsx" />
        </ListItem>
        <ListItem data-sentry-element="ListItem" data-sentry-source-file="ConsumerProductMatrixCard.components.tsx">
          <Translation da="Daglige nyhedsbreve med de vigtigste branchenyheder" de="Tägliche Newsletter mit den wichtigsten Updates" en="Daily newsletters with the most important industry news" no="Daglige nyhetsbrev med de viktigste bransjenyhetene" sv="Dagliga nyhetsbrev med de viktigaste branschnyheterna" data-sentry-element="Translation" data-sentry-source-file="ConsumerProductMatrixCard.components.tsx" />
        </ListItem>
        <ListItem data-sentry-element="ListItem" data-sentry-source-file="ConsumerProductMatrixCard.components.tsx">
          <Translation da="Topnyhedsbreve når de største branchenyheder breaker" de="Eilmeldungen per E-Mail, damit Sie immer auf dem Laufenden sind" en="Top newsletters when the biggest industry news breaks" no="Toppnyhetsbrev når de største bransjenyhetene bryter" sv="Toppnyhetsbrev när de största branschnyheterna bryter" data-sentry-element="Translation" data-sentry-source-file="ConsumerProductMatrixCard.components.tsx" />
        </ListItem>
        {apps?.appStoreUrl !== null && apps?.googlePlayUrl !== null ? <ListItem>
            <Translation da="Login til vores app - branchenyheder med på farten" de="Anmeldung zu unserer App - Branchennachrichten unterwegs" en="Login to our app - industry news on the go" no="Logg inn på appen vår - bransjenyheter på farten" sv="Logga in på vår app - branschnyheter på språng" />
          </ListItem> : null}
      </List>
      {billingPeriod === "Quarterly" && priceWithCurrency !== null ? <div>
          <p className="font-semibold">
            <Translation da="Kvartalsvis" de="Vierteljährlich" en="Quarterly" no="Kvartalsvis" sv="Kvartalsvis" />
          </p>
          <h3 className="mb-2 text-2xl font-bold">{`${priceWithCurrency}*`}</h3>
        </div> : null}
      <RegularLinkButton href={url} isDisabled={isDisabled} position="pw-bbtn" data-sentry-element="RegularLinkButton" data-sentry-source-file="ConsumerProductMatrixCard.components.tsx">
        <Translation da="Køb" de="Kaufen" en="Buy" no="Kjøp" sv="Köp" data-sentry-element="Translation" data-sentry-source-file="ConsumerProductMatrixCard.components.tsx" />
      </RegularLinkButton>
    </Card>;
}
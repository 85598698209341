import clsx from "clsx";
import { RegularLinkButton } from "../Button/RegularLinkButton/RegularLinkButton.component";
import { Card } from "../Card/Card.component";
import { Divider } from "../Divider/Divider.component";
import { List } from "../List/List.component";
import { ListItem } from "../List/ListItem.component";
import type { PaywallModel } from "../Paywall/models/paywall.model";
import { Translation } from "../Translation/Translation.component";
type CorporateProductMatrixCardProps = {
  readonly url: string;
  readonly isInverted?: boolean;
  readonly apps: PaywallModel.Apps | null;
  readonly isDisabled: boolean;
};
export function CorporateProductMatrixCard({
  isInverted = false,
  url,
  isDisabled
}: CorporateProductMatrixCardProps) {
  return <Card className="flex h-full flex-col justify-between gap-y-2 rounded border-silver text-center lg:px-4" isInverted={isInverted} data-sentry-element="Card" data-sentry-component="CorporateProductMatrixCard" data-sentry-source-file="CorporateProductMatrixCard.components.tsx">
      <h1 className={clsx("hyphens-manual break-words text-xl font-bold leading-tight", isInverted ? "text-white" : "text-black")}>
        <Translation da="Virksomheds&shy;abonnement" de="Team- und unternehmens&shy;weite Lizenz" en="Corporate subscription" no="Bedrifts&shy;abonnement" sv="Företags&shy;abonnemang" data-sentry-element="Translation" data-sentry-source-file="CorporateProductMatrixCard.components.tsx" />
      </h1>
      <Divider className="relative" isLight={isInverted} orientation="horizontal" data-sentry-element="Divider" data-sentry-source-file="CorporateProductMatrixCard.components.tsx" />
      <p className="text-left">
        <Translation da="Med et virksomhedsabonnement får du:" de="Mit einem Business-Abonnement erhalten Sie:" en="With a corporate subscription you get:" no="Med et bedriftsabonnement får du:" sv="Med ett företagsabonnemang får du:" data-sentry-element="Translation" data-sentry-source-file="CorporateProductMatrixCard.components.tsx" />
      </p>
      <List className="gap-1 text-left" type="checkmark" data-sentry-element="List" data-sentry-source-file="CorporateProductMatrixCard.components.tsx">
        <ListItem data-sentry-element="ListItem" data-sentry-source-file="CorporateProductMatrixCard.components.tsx">
          <Translation da="Alle fordele fra et personligt abonnement" de="Vorteile Ihrer persönlichen Lizenz" en="All the benefits of a personal subscription" no="Alle fordelene fra våre personlige abonnementer" sv="Alla fördelar med en personlig prenumeration" data-sentry-element="Translation" data-sentry-source-file="CorporateProductMatrixCard.components.tsx" />
        </ListItem>
        <ListItem data-sentry-element="ListItem" data-sentry-source-file="CorporateProductMatrixCard.components.tsx">
          <Translation da="Løsning tilpasset din virksomheds behov" de="Lösung, die auf die Bedürfnisse Ihres Unternehmens zugeschnitten ist" en="Solution tailored to your company's needs" no="Løsning tilpasset din bedrifts behov" sv="Lösning anpassad till ditt företags behov" data-sentry-element="Translation" data-sentry-source-file="CorporateProductMatrixCard.components.tsx" />
        </ListItem>
        <ListItem data-sentry-element="ListItem" data-sentry-source-file="CorporateProductMatrixCard.components.tsx">
          <Translation da="Attraktiv pris per bruger" de="Attraktiver Preis pro Benutzer" en="Attractive price per user" no="Attraktiv pris per bruker" sv="Attraktivt pris per användare" data-sentry-element="Translation" data-sentry-source-file="CorporateProductMatrixCard.components.tsx" />
        </ListItem>
      </List>

      <RegularLinkButton href={url} isDisabled={isDisabled} position="pw-co-bbtn" variant="secondary" data-sentry-element="RegularLinkButton" data-sentry-source-file="CorporateProductMatrixCard.components.tsx">
        <Translation da="Bestil adgang" de="Zugang bestellen" en="Order access" no="Bestill tilgang" sv="Beställ åtkomst" data-sentry-element="Translation" data-sentry-source-file="CorporateProductMatrixCard.components.tsx" />
      </RegularLinkButton>
    </Card>;
}
"use client";

import { clsx } from "clsx";
import type { FormEvent, ReactNode } from "react";
import { Link } from "@/components/Link/Link.component";
import { List } from "@/components/List/List.component";
import { ListItem } from "@/components/List/ListItem.component";
import { Translation } from "@/components/Translation/Translation.component";
import { useSite } from "@/contexts/site/site.context";
import type { ConversionEvent } from "@/hooks/tracking/kilkaya/klikaya.types";
import { useKilkayaConversion } from "@/hooks/tracking/kilkaya/use-kilkaya-tracking.hook";
import { inlineJsx } from "@/ts/locale/inline-jsx";
type SuccessBoxProps = {
  readonly className?: string;
  readonly appStoreUrl: string | null;
  readonly googlePlayUrl: string | null;
  readonly ctaButton: ReactNode;
  readonly onSubmit?: (event: FormEvent<HTMLFormElement>) => void;
  readonly conversionEventTags: ConversionEvent;
};
export const successMessageTranslations = (siteName: string) => ({
  da: `Du har nu startet din prøveperiode på ${siteName}`,
  de: `Sie haben jetzt Ihr Probeabonnement für ${siteName} begonnen`,
  en: `Your trial for ${siteName} has now started`,
  no: `Du har nå startet ditt prøveabonnement på ${siteName}`,
  sv: `Din provperiod för ${siteName} har nu startat`
});
export function SuccessBox({
  className,
  appStoreUrl,
  googlePlayUrl,
  ctaButton,
  onSubmit,
  conversionEventTags
}: SuccessBoxProps) {
  const {
    name: siteName
  } = useSite();
  const translations = successMessageTranslations(siteName);
  useKilkayaConversion(conversionEventTags);
  const appStoreLink = appStoreUrl !== null ? <Link href={appStoreUrl} variant="primary" openInNewTab>
        App Store
      </Link> : <p>App Store</p>;
  const googlePlayLink = googlePlayUrl !== null ? <Link href={googlePlayUrl} variant="primary" openInNewTab>
        Google Play
      </Link> : <p>Google Play</p>;
  return <form className={clsx(className, "flex flex-col gap-y-2 border border-solid border-silver bg-whisper p-2 lg:mr-2.5 lg:px-9 lg:pb-7 lg:pt-8")} onSubmit={onSubmit} data-sentry-component="SuccessBox" data-sentry-source-file="SuccessBox.component.tsx">
      <h1 className="hyphens-manual break-words border-b border-solid border-silver pb-2 font-heading text-3xl font-theme-bold lg:text-4xl">
        <Translation model={translations} data-sentry-element="Translation" data-sentry-source-file="SuccessBox.component.tsx" />
      </h1>
      <h2 className="font-theme-bold">
        <Translation da="Med din prøveperiode får du:" de="Mit Ihrem Probeabonnement erhalten Sie:" en="With your free trial you get:" no="Dette er inkludert i ditt prøveabonnement:" sv="Med din provperiod får du:" data-sentry-element="Translation" data-sentry-source-file="SuccessBox.component.tsx" />
      </h2>
      <List className="space-y-1" type="bullet" data-sentry-element="List" data-sentry-source-file="SuccessBox.component.tsx">
        <ListItem data-sentry-element="ListItem" data-sentry-source-file="SuccessBox.component.tsx">
          <Translation da={`Fuld adgang til alle låste artikler på ${siteName}.`} de={`Unbegrenzter Zugang zu allen Artikeln auf ${siteName}.`} en={`Full access to all locked articles on ${siteName}.`} no={`Full tilgang til alle låste artikler på ${siteName}.`} sv={`Full tillgång till alla låsta artiklar på ${siteName}.`} data-sentry-element="Translation" data-sentry-source-file="SuccessBox.component.tsx" />
        </ListItem>
        {appStoreUrl !== null ? <ListItem>
            <Translation da={inlineJsx`Fuld adgang til vores app. Hent den i ${appStoreLink} eller ${googlePlayLink}.`} de={inlineJsx`Unbegrenzter Zugang zu unser App. Erhalten Sie die App über ${appStoreLink} oder ${googlePlayLink}.`} en={inlineJsx`Full access to our app. Get it on ${appStoreLink} or ${googlePlayLink}.`} no={inlineJsx`Full tilgang til vår app. Hent den i ${appStoreLink} eller ${googlePlayLink}.`} sv={inlineJsx`Full tillgång till vår app. Hämta den i ${appStoreLink} eller ${googlePlayLink}.`} />
          </ListItem> : null}
        <ListItem data-sentry-element="ListItem" data-sentry-source-file="SuccessBox.component.tsx">
          <Translation da="Daglige nyhedsbreve samt løbende top-nyhedsbreve. Du kan afmelde dig nyhedsbreve ved at klikke på dit brugernavn, når du er logget ind." de="Täglicher Newsletter sowie laufende Top-Newsletter. Sie können sich jederzeit von unserem kostenlosen Newsletter an- und abmelden." en="Daily newsletter and ongoing top-newsletters. You can unsubscribe and subscribe to our newsletters anytime." no="Daglige nyhetsbrev samt løpende toppnyhetsbrev. Du kan melde deg på og av våre nyhetsbrev ved å klikke på ditt brukernavn når du er logget inn." sv="Dagliga nyhetsbrev samt löpande top-nyhetsbrev. Du kan prenumerera och avprenumerera på våra nyhetsbrev när som helst." data-sentry-element="Translation" data-sentry-source-file="SuccessBox.component.tsx" />
        </ListItem>
      </List>
      <h2 className="mt-2 font-theme-bold lg:mt-4">
        <Translation da="Din prøveperiode udløber automatisk" de="Nach Ende des Probezeitraums" en="When your trial period expires" no="Når din prøveperiode utløper" sv="När din provperiod löper ut" data-sentry-element="Translation" data-sentry-source-file="SuccessBox.component.tsx" />
      </h2>
      <List className="space-y-1" type="bullet" data-sentry-element="List" data-sentry-source-file="SuccessBox.component.tsx">
        <ListItem className="font-bold" data-sentry-element="ListItem" data-sentry-source-file="SuccessBox.component.tsx">
          <Translation da="Du vil ikke efterfølgende overgå til et betalt abonnement." de="Das Ende der Testphase löst kein kostenpflichtiges Abo aus." en="You will not be transferred to a paid subscription." no="Du vil ikke bli overført til et betalt abonnement i etterkant." sv="Du kommer inte att övergå till en betald prenumeration." data-sentry-element="Translation" data-sentry-source-file="SuccessBox.component.tsx" />
        </ListItem>
        <ListItem data-sentry-element="ListItem" data-sentry-source-file="SuccessBox.component.tsx">
          <Translation da="Du vil fortsat modtage nyhedsbrevet efter endt prøveperiode. Du kan afmelde dig i bunden af det enkelte nyhedsbrev." de="Sie erhalten unsere Newsletter auch nach Ende der Testphase. Am Ende jedes Newsletters haben Sie die Möglichkeit zum Abbestellen." en="You will continue to receive our newsletters after the trial period expires. You can unsubscribe at the bottom of each newsletter." no="Du vil fortsatt motta nyhetsbrevet etter prøveperioden. Du kan melde deg av i bunnen av det spesifikke nyhetsbrevet." sv="Du kommer att fortsätta att få våra nyhetsbrev efter att provperioden löpt ut. Du kan avprenumerera längst ner i varje nyhetsbrev." data-sentry-element="Translation" data-sentry-source-file="SuccessBox.component.tsx" />
        </ListItem>
      </List>
      {ctaButton}
    </form>;
}
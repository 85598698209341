"use client";

import clsx from "clsx";
import { usePathname, useRouter } from "next/navigation";
import type { FormEvent } from "react";
import { useState } from "react";
import { addTrial } from "@/app/(sites)/_containers/_actions/add-trial";
import { ErrorMessage } from "@/components/ErrorMessage/ErrorMessage.component";
import { ConsumerProductMatrixCard } from "@/components/ProductMatrixCard/ConsumerProductMatrixCard.components";
import { CorporateProductMatrixCard } from "@/components/ProductMatrixCard/CorporateProductMatrixCard.components";
import { Translation } from "@/components/Translation/Translation.component";
import { useSite } from "@/contexts/site/site.context";
import { useUser } from "@/contexts/user/user.context";
import { conversionEvents } from "@/hooks/tracking/kilkaya/klikaya.types";
import { useKilkayaClickEvent } from "@/hooks/tracking/kilkaya/use-kilkaya-tracking.hook";
import type { ErrorMessageStatus, Fetchable } from "@/services/utilities/fetchable";
import { hasFailed, failed, succeeded, pending, initial, isPending } from "@/services/utilities/fetchable";
import { inlineJsx } from "@/ts/locale/inline-jsx";
import { RegularButton } from "../Button/RegularButton/RegularButton.component";
import { Divider } from "../Divider/Divider.component";
import { Link } from "../Link/Link.component";
import { LoginLink } from "../Login/LoginLink/LoginLink.component";
import { Spinner } from "../Spinner/Spinner.component";
import { SwitchFetchable } from "../SwitchFetchable/SwitchFetchable.component";
import type { PaywallModel } from "./models/paywall.model";
import { SuccessBox } from "./SuccessBox/_components/SuccessBox.component";
type PaywallProps = {
  readonly className?: string;
  readonly model: PaywallModel;
  readonly redirectUrl: string;
  readonly initialSubmissionState?: Fetchable;
};
export const trialLinkTranslations = {
  da: "Få et uforpligtende prøveabonnement her.",
  de: "Erhalten Sie hier ein unverbindliches Probeabonnement.",
  en: "Get a non-binding trial subscription here.",
  no: "Få et uforpliktende prøveabonnement her.",
  sv: "Få ett icke-bindande provabonnemang här."
};
export function Paywall({
  className,
  model,
  initialSubmissionState = initial(),
  redirectUrl
}: PaywallProps) {
  const pathname = usePathname();
  const {
    trackClick
  } = useKilkayaClickEvent();
  const {
    name: siteName
  } = useSite();
  const {
    product,
    trial,
    apps,
    articleId,
    termsAndConditionsUrl
  } = model;
  const router = useRouter();
  const {
    isLoggedIn
  } = useUser();
  const {
    model: userModel
  } = useUser();
  const [containerState, setContainerState] = useState(initialSubmissionState);
  const [refreshArticleState, setRefreshArticleState] = useState<Fetchable>(initial());
  async function trialClick() {
    setContainerState(pending());
    if (!userModel.loggedIn) {
      return failed("NOT_LOGGED_IN");
    }
    const result = await addTrial({
      articleId
    });
    trackClick({
      channel: "profile",
      position: "pw-adtr-btn",
      toUrl: pathname,
      url: pathname
    });
    setContainerState(result.status !== "CREATED" ? failed(result.status) : succeeded());
  }
  function refreshPage(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setRefreshArticleState(pending());
    router.refresh();
  }
  return <SwitchFetchable fetchable={containerState} renderDefault={() => <article className={clsx(className, "relative text-sm md:text-base lg:mr-2.5")}>
          {!isLoggedIn ? <div className="absolute -right-1 top-1 flex select-none whitespace-nowrap border border-solid border-silver bg-white px-2 py-1 text-xs uppercase sm:top-1.5 lg:-right-2.5 lg:top-1.5">
              <span className="border-r border-solid border-silver pr-1">
                <Translation da="Allerede abonnent?" de="Bereits registriert?" en="Already a subscriber?" no="Allerede abonnent?" sv="Redan prenumerant?" />
              </span>
              <LoginLink className="ml-1 font-theme-bold text-primary hover:text-primary-hover" position="pw_login" variant="inherit">
                <Translation da="Log ind her" de="Hier anmelden" en="Log in here" no="Logg inn her" sv="Logga in här" />
              </LoginLink>
            </div> : null}
          <div className={clsx("flex flex-col gap-y-2 bg-whisper px-2 py-4 lg:px-4 lg:py-8", !isLoggedIn && "pt-8")}>
            <h1 className="pb-2 text-center font-heading text-3xl font-theme-bold md:text-4xl lg:text-5xl">
              {isLoggedIn ? <Translation da={`Du er logget ind, men du er ikke tilknyttet et aktivt abonnement på ${siteName}`} de={`Sie sind angemeldet, aber mit keinem aktiven Abonnement für ${siteName} verbunden`} en={`You are logged in, but you do not have an active subscription to ${siteName}`} no={`Du er logget inn, men du er ikke tilknyttet et aktivt abonnement på ${siteName}`} sv={`Du är inlogg, men har inte ett aktivt abonnemang på ${siteName}`} /> : <Translation da={`Køb abonnement og få adgang til hele ${siteName} `} de={`Abonnement kaufen und Zugang zu ${siteName} erhalten `} en={`Buy a subscription and get access to all of ${siteName} `} no={`Kjøp abonnement og få tilgang til hele ${siteName} `} sv={`Köp en prenumeration och få tillgång till hela ${siteName} `} />}
            </h1>
            <ConsumerProductMatrixCard apps={apps} billingPeriod={product.billingPeriod} isDisabled={isPending(containerState)} priceWithCurrency={product.pricePerQuarter} siteName={siteName} url={`/shop/consumer?articleId=${articleId}`} />

            <CorporateProductMatrixCard apps={apps} isDisabled={isPending(containerState)} url={`/shop/corporate?&articleId=${articleId}`} />

            {trial !== null && !trial.isUserTrialExpired ? <div className="text-center text-sm md:text-base">
                {isPending(containerState) ? null : <p>
                    <Translation da={inlineJsx`Prøv ${siteName} i ${trial.numberOfFreeTrialDays} dage - og få adgang til alt indhold.`} de={inlineJsx`Testen Sie ${siteName} ${trial.numberOfFreeTrialDays} Tage lang - und erhalten Sie Zugang zu allen Inhalten.`} en={inlineJsx`Try ${siteName} for ${trial.numberOfFreeTrialDays} days - and get access to all content.`} no={inlineJsx`Prøv ${siteName} i ${trial.numberOfFreeTrialDays} dager - og få tilgang til alt innhold.`} sv={inlineJsx`Prova ${siteName} i ${trial.numberOfFreeTrialDays} dagar - och få tillgång till allt innehåll.`} />
                  </p>}

                {isLoggedIn && !isPending(containerState) ? <button className="text-center text-primary hover:underline" data-k5a-pos="pw-adtr-btn" type="submit" onClick={trialClick}>
                    <Translation model={trialLinkTranslations} />
                  </button> : null}
                {isLoggedIn && isPending(containerState) ? <div className="flex justify-center">
                    <Spinner className="h-3 w-3 rounded-full fill-primary text-whisper group-disabled:fill-silver" />
                  </div> : null}

                {!isLoggedIn ? <Link className="text-primary" href={`/profile/create/trial?redirectUrl=${encodeURIComponent(redirectUrl)}`} position="pw-adtr-a">
                    <Translation model={trialLinkTranslations} />{" "}
                  </Link> : null}
              </div> : null}
            {hasFailed(containerState) ? <ErrorMessage status={(containerState.errorMessage as ErrorMessageStatus)} /> : null}
            {product.pricePerQuarter !== null ? <div className="container flex max-w-lg flex-col gap-2 text-center text-xs">
                <Divider className="relative" orientation="horizontal" />
                <p>
                  <Translation da={`* Mindstepris: ${product.pricePerQuarter} for 3 måneder v/betaling med kreditkort. Fortsætter som løbende abonnement for 3 måneder ad gangen. Kan opsiges med løbende måned plus en måned til udgangen af en 3 måneders periode. Fortrydelsesret i medfør af Forbrugeraftaleloven `} de={`* Mindestpreis: ${product.pricePerQuarter} für 3 Monate bei Zahlung mit Kreditkarte. Wird als fortlaufendes Abonnement für 3 Monate fortgesetzt. Kann mit einer Kündigungsfrist von einem Monat zum Ende eines 3-Monats-Zeitraums gekündigt werden. Widerrufsrecht gemäß dem Verbrauchervertragsgesetz `} en={`* Minimum price: ${product.pricePerQuarter} for 3 months when paying by credit card. Continues as a recurring subscription for 3 months at a time. Can be terminated with one month's notice at the end of a 3-month period. Right of withdrawal in accordance with the Consumer Contracts Act `} no={`* Minimumspris: ${product.pricePerQuarter} for 3 måneder ved betaling med kredittkort. Fortsetter som løpende abonnement for 3 måneder av gangen. Kan sies opp med en måneds varsel ved utgangen av en 3-måneders periode. Angrerett i henhold til forbrukerloven `} sv={`* Minsta pris: ${product.pricePerQuarter} för 3 månader vid betalning med kreditkort. Fortsätter som löpande prenumeration i 3 månader i taget. Kan sägas upp med en månads varsel i slutet av en 3-månaders period. Ångerrätt enligt konsumentavtalslagen `} />
                  <Link className="inline underline hover:text-primary" href={termsAndConditionsUrl}>
                    <Translation da="Læs mere her" de="Lesen Sie mehr hier" en="Read more" no="Les mer her" sv="Läs mer här" />
                  </Link>
                </p>
              </div> : null}
          </div>
        </article>} renderSucceeded={() => <SuccessBox appStoreUrl={apps?.appStoreUrl ?? null} className={className} conversionEventTags={conversionEvents.addTrialArticleEvent} ctaButton={<RegularButton className="mt-2 w-full" isPending={isPending(refreshArticleState)} type="submit">
              <Translation da="Klik her og læs artiklen" de="Klicken Sie hier und lesen Sie den Artikel" en="Click here and read the article" no="Klikk her og les artikkelen" sv="Klicka här och läs artikeln" />
            </RegularButton>} googlePlayUrl={apps?.googlePlayUrl ?? null} onSubmit={refreshPage} />} data-sentry-element="SwitchFetchable" data-sentry-component="Paywall" data-sentry-source-file="Paywall.component.tsx" />;
}